<template>
  <div class="userHistory">
    <PageNav title="我的足迹" />
    <div class="">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="fetchList"
      >
        <div class="prod-list">
          <div class="prod-item" v-for="item in list" :key="item.productId" @click="onClickProd(item.productId)">
            <van-image
              lazy-load
              :src="item.titleImg"
            />
            <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
            <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
              <span>￥{{ item.price }}</span>
              <span>选购</span>
            </div>
            <div v-else class="price_pannel">
              <span>买方报价</span>
              <span>报价</span>
            </div>
          </div> 
        </div>
      </van-list>
      <van-empty v-show="list.length == 0" description="暂无数据" />
    </div>
  </div>
</template>

<script>
import Fetch from '@/common/fetch';
import { GetHistoryList } from '@/common/interface';
import PageNav from '../components/PageNav.vue';
import Vue from 'vue';
import { Lazyload } from 'vant';

Vue.use(Lazyload);

export default {
  name: "UserHistory",
  components: { PageNav },
  data() {
    return {
      curPage: 1,
      loading: false,
      finished: false,
      list: [],
    };
  },
  methods: {
    fetchList() {
      let page = this.curPage;
      const params = {
        saleType: 1,
        pageNum: page,
        pageSize: 20,
      };
      Fetch.post(GetHistoryList, params).then((res) => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = this.list.concat(resList);
        this.curPage = page + 1;
        this.list = newList;
        this.loading = false;
        this.finished = finished;
      }).catch(() => {
      });
    },

    onClickProd(productId) {
      this.$router.push('/product?id=' + productId);
    },
  },
}
</script>